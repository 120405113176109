<template>
    <router-view />
</template>

<script>
    export default {
        name: "App",
        components: {},
    };
</script>

<style>
    body,
    html {
        width: 100%;
        height: 100%;
    }
</style>
