import { createRouter, createWebHistory } from "vue-router";
// import useAuthUser from "@/composables/UseAuthUser";

const routes = [

    { name: "Home", path: "/", component: () => import("@/pages/Home.vue") },
    { name: "Landing", path: "/landing/:id", component: () => import('@/pages/Landing.vue') },
    { name: "Video", path: "/video/:id", component: () => import('@/pages/Video.vue') },
    { name: "Image", path: "/image/:id", component: () => import('@/pages/Image.vue') },
    { name: "PDF Media", path: "/pdf-media/:id", component: () => import('@/pages/PdfMedia.vue') },

    // { name: "Me", path: "/me", component: () => import("@/pages/Me.vue"), meta: { requiresAuth: true } },
    // { name: "Test", path: "/test", component: () => import("@/pages/Test.vue"), meta: { requiresAuth: true } },
    // { name: "Login", path: "/login", component: () => import("@/pages/Login.vue") },

    // { name: "Register", path: "/register", component: () => import("@/pages/Register.vue") },
    // { name: "EmailConfirmation", path: "/email-confirmation", component: () => import("@/pages/EmailConfirmation.vue") },
    // { name: "ForgotPassword", path: "/forgot-password", component: () => import("@/pages/ForgotPassword.vue") },

    // { name: "Logout", path: "/logout", beforeEnter: async () => {
    //     const { logout } = useAuthUser();
    //     await logout();

    //     return { name: "Home" };
    // }},
]


const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history',
});

// router.beforeEach( to => {

//     const { isLoggedIn } = useAuthUser();
//     if (!isLoggedIn() && to.meta.requiresAuth) {
//         return { name: 'Login' };        
//     }
// })

export default router;